<template>
  <v-navigation-drawer
    temporary
    stateless
    absolute
    right
    width="768"
    :value="pOpen"
  >
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <template v-slot:prepend>
      <v-toolbar outlined>
        <v-toolbar-title class="title"> 新增号码列表 </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
    </template>
    <v-card flat>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-textarea
              outlined
              dense
              label="号码列表"
              v-model="form.numbers"
            ></v-textarea>
          </v-col>
          <v-col cols="3">
            <v-text-field
              outlined
              dense
              label="通话费率(元)"
              v-model="form.callFee"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              outlined
              dense
              label="月租费(元)"
              v-model="form.monthlyFee"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              outlined
              dense
              label="省份"
              v-model="form.province"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              outlined
              dense
              label="城市"
              v-model="form.city"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              outlined
              dense
              :loading="pSubtypes.length === 0"
              label="呼入/呼出"
              :items="pSubtypes"
              v-model="form.subtype"
            ></v-select>
          </v-col>
          <v-col :cols="6">
            <v-select
              outlined
              dense
              :loading="pNumberTypes.length === 0"
              label="号码类型"
              :items="pNumberTypes"
              v-model="form.numberType"
            ></v-select>
          </v-col>
          <v-col :cols="6">
            <v-select
              outlined
              dense
              :loading="pAgentTypes.length === 0"
              label="代理商"
              :items="pAgentTypes"
              v-model="form.agentType"
            ></v-select>
          </v-col>
          <v-col :cols="6">
            <v-select
              outlined
              dense
              :loading="pNumberStatuses.length === 0"
              label="号码状态"
              :items="pNumberStatuses"
              v-model="form.status"
            ></v-select>
          </v-col>
          <v-col :cols="6">
            <v-checkbox
              dense
              label="是否靓号"
              v-model="form.isGood"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <template v-slot:append>
      <v-toolbar outlined flat>
        <v-btn text @click="close">取消</v-btn>
        <v-spacer></v-spacer>
        <v-btn text dark class="primary--text" @click="add"> 提交 </v-btn>
      </v-toolbar>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { AliyunCallPushNumbers } from "../../api/aliyunCall";

export default {
  props: {
    pOpen: Boolean,
    pForm: Object,
    pSubtypes: Array,
    pNumberTypes: Array,
    pAgentTypes: Array,
    pNumberStatuses: Array,
  },
  data() {
    return {
      loading: false,
      form: {},
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async add() {
      this.loading = true;
      this.form.callFee = parseFloat(this.form.callFee);
      this.form.monthlyFee = parseFloat(this.form.monthlyFee);
      const err = await AliyunCallPushNumbers(this.form).catch((e) =>
        Error(e)
      );
      this.loading = false;
      if (err instanceof Error) {
        console.log("Error: ", err.message);
        return;
      }
      this.$emit("update");
    },
  },
  watch: {
    pForm() {
      this.form = this.pForm;
    },
  },
};
</script>
