import request from "../utils/request";

export function ItemsQuery(item) {
  return request.get("/xtboss/AliyunCall/items/" + item);
}

export function AliyunCallListNumbers() {
  return request.post("/xtboss/AliyunCall/listNumbers.do");
}

export function AliyunCallPushNumbers(data) {
  return request.post("/xtboss/AliyunCall/pushNumbers.do", data);
}

// export function AliyunCallUpdateNumber(data) {
//   return request.post("/xtboss/AliyunCall/updateNumber.do", data);
// }

export function AliyunCallDeleteNumber(data) {
  return request.post("/xtboss/AliyunCall/deleteNumber.do", data);
}
