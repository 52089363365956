<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        Aliyun Call
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn @click="openExpansionDrawer">新增</v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          dense
          filled
          v-model="search"
          append-icon="mdi-magnify"
          label="搜索"
          single-line
          hide-details
          append-outer-icon="mdi-refresh"
          @click:append-outer="aliyunCallListNumbers"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :search="search"
        :headers="headers"
        :items="items"
        item-key="number"
        disable-pagination
        disable-sort
        hide-default-footer
        single-expand
        show-expand
        :expanded.sync="expanded"
      >
        <template v-slot:item.isGood.text="{ item }">
          <v-chip v-if="item.isGood.value" x-small dark color="blue">
            {{ item.isGood.text }}
          </v-chip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container fluid>
              <v-card outlined>
                <v-card-text>
                  <NumberDetails :pObject="item"></NumberDetails>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn dark color="red" @click.stop="openDeleteDialog(item)">
                    删除
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <ExpansionDrawer
      :pOpen="expansionDrawer.open"
      :pMode="expansionDrawer.mode"
      :pForm="expansionDrawer.form"
      :pSubtypes="subtypes"
      :pNumberTypes="numberTypes"
      :pAgentTypes="agentTypes"
      :pNumberStatuses="numberStatuses"
      @close="expansionDrawerOnClose"
      @update="expansionDrawerOnUpdate"
    ></ExpansionDrawer>
    <v-dialog width="1024" v-model="dialog">
      <v-card>
        <v-card-title>确认删除号码</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <NumberDetails :pObject="tmpNumberDetails"></NumberDetails>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            dark
            color="red"
            :loading="loading"
            @click.stop="aliyunCallDeleteNumber(tmpNumberDetails)"
          >
            确认
          </v-btn>
          <v-btn text @click.stop="dialog = false">取消</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  ItemsQuery,
  AliyunCallListNumbers,
  AliyunCallDeleteNumber,
} from "../../api/aliyunCall";
import ExpansionDrawer from "./expansion-drawer";
import NumberDetails from "./number-details";

export default {
  components: { ExpansionDrawer, NumberDetails },
  data: () => ({
    dialog: false,
    loading: true,
    search: "",
    expanded: [],
    headers: [
      { text: "", value: "isGood.text", width: "4%" },
      { text: "号码", value: "number" },
      { text: "通话费率(元)", value: "callFee", width: "8%", align: "end" },
      { text: "月租费(元)", value: "monthlyFee", width: "8%", align: "end" },
      { text: "省份", value: "province", width: "8%", align: "end" },
      { text: "城市", value: "city", width: "8%", align: "end" },
      { text: "呼入/呼出", value: "subtype.text", width: "8%", align: "end" },
      { text: "号码类型", value: "numberType.text", width: "8%", align: "end" },
      { text: "代理商", value: "agentType.text", width: "8%", align: "end" },
      { text: "号码状态", value: "status.text", width: "8%", align: "end" },
      { text: "", value: "data-table-expand", width: "4%", align: "end" },
    ],
    items: [],
    expansionDrawer: { open: false, form: {} },
    subtypes: [],
    numberTypes: [],
    agentTypes: [],
    numberStatuses: [],
    tmpNumberDetails: {},
  }),
  methods: {
    aliyunCallListNumbers() {
      this.loading = true;
      this.items = [];
      AliyunCallListNumbers()
        .then((res) => {
          this.items = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    expansionDrawerOnClose() {
      this.expansionDrawer = { open: false, form: {} };
    },
    expansionDrawerOnUpdate() {
      this.aliyunCallListNumbers();
      this.expansionDrawerOnClose();
    },
    openExpansionDrawer() {
      this.expansionDrawer = {
        open: true,
        form: {
          callFee: "00.00",
          monthlyFee: "00.00",
          subtype: 0,
          numberType: 1,
          agentType: 0,
          status: 0,
        },
      };
    },
    openDeleteDialog(numberDetails) {
      this.tmpNumberDetails = numberDetails;
      this.dialog = true;
    },
    aliyunCallDeleteNumber(number) {
      this.loading = true;
      AliyunCallDeleteNumber(number)
        .then(() => {
          this.aliyunCallListNumbers();
          this.dialog = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    ItemsQuery("subtypes").then((res) => {
      this.subtypes = res.data;
    });
    ItemsQuery("number-types").then((res) => {
      this.numberTypes = res.data;
    });
    ItemsQuery("agent-types").then((res) => {
      this.agentTypes = res.data;
    });
    ItemsQuery("number-statuses").then((res) => {
      this.numberStatuses = res.data;
    });
    this.aliyunCallListNumbers();
  },
};
</script>
