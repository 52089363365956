<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2" class="text-right">号码:</v-col>
      <v-col cols="2">{{ pObject.number }}</v-col>
      <v-col cols="2" class="text-right">通话费率(元):</v-col>
      <v-col cols="2">{{ pObject.callFee }}</v-col>
      <v-col cols="2" class="text-right">月租费(元):</v-col>
      <v-col cols="2">{{ pObject.monthlyFee }} </v-col>
      <v-col cols="2" class="text-right">省份:</v-col>
      <v-col cols="2">{{ pObject.province }}</v-col>
      <v-col cols="2" class="text-right">城市:</v-col>
      <v-col cols="2">{{ pObject.city }}</v-col>
      <v-col cols="2" class="text-right">呼入/呼出:</v-col>
      <v-col cols="2">{{ pObject.subtype.text }} </v-col>
      <v-col cols="2" class="text-right">号码类型:</v-col>
      <v-col cols="2">{{ pObject.numberType.text }} </v-col>
      <v-col cols="2" class="text-right">代理商:</v-col>
      <v-col cols="2">{{ pObject.agentType.text }} </v-col>
      <v-col cols="2" class="text-right">号码状态:</v-col>
      <v-col cols="2">{{ pObject.status.text }} </v-col>
      <template v-if="pObject.isGood.value">
        <v-col cols="2" class="text-right">
          <v-chip x-small dark color="blue">
            {{ pObject.isGood.text }}
          </v-chip>
        </v-col>
        <v-col cols="10"></v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    pObject: Object,
  },
};
</script>
